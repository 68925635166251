<template>
  <div :class="['c-cartItems', {'-isEmpty': !hasItems}, {'-isCheckout': props.isCheckout}]">
    <template v-if="hasItems">
      <Typography
        class="-fontMedium h-mb-20"
        v-text="$t('widgets.cart.title')"
        variant="body2"
      />
      <div
        :class="['c-itemRow', { '-isCheckout': props.isCheckout }]"
        v-for="item in cartItems"
        :key="item.name"
      >
        <CardLoader v-if="isLoading === item.row_id" />
        <ItemQuantity
          @newQty="handleItemQty($event, item)"
          :value="item.qty"
          is-small
        />
        <div class="c-itemRow__titleWrapper">
          <Typography
            :class="['c-itemRow__title -fontMedium h-mt-0', props.isCheckout && '-adjustWidth']"
            v-text="item.name"
            variant="body2"
          />
          <template
            v-for="(variant, i) in item.variants"
            :key="generateKey(variant.name)"
          >
            <small
              class="body2 -muted"
              v-text="`${variant.name}`"
            />
            <select
              style="margin-right: 3px;"
              v-if="variant.choose_multiple"
              @change="handleItemVariant($event.target.value, item, variant)"
            >
              <option
                v-for="n in 9"
                :value="n"
                :key="n"
                :selected="variant.qty === n"
              >
                {{ n }}
              </option>
            </select>
            <small
              v-if="i <= (item.variants.length - 2)"
              class="body2 -muted"
              v-text="', '"
            />
          </template>
        </div>
        <BaseButton
          v-if="item.perfectMatch && isCheckout && !matchInCart(item.perfectMatch.id)"
          class="c-perfectMatchCta"
          :variant="isMobile ? 'outlined' : 'outlined'"
          :color="isMobile ? 'muted' : 'tertiary'"
          icon="perfect-match"
          icon-color="primary"
          icon-width="14"
          @click="goToPdp(item.perfectMatch)"
        >
          <template
            #html-label
            v-if="!isMobile"
          >
            <span
              v-html="$t('common.ctaLabels.perfectMatch')"
            />
          </template>
        </BaseButton>
        <Typography
          :class="[
            'c-itemRow__price -fontMedium -muted h-mt-0',
            {'-hasPerfectMatch':
              item.perfectMatch && isCheckout && !matchInCart(item.perfectMatch.id)}
          ]"
          v-text="calculatePrice(item)"
          variant="body2"
        />
        <BaseButton
          v-if="isCheckout"
          :variant="isMobile ? 'outlined' : 'default'"
          color="muted"
          class="c-itemRow__delete"
          :btn-label="isMobile ? '' : $t('common.ctaLabels.delete')"
          :icon="isMobile ? 'delete' : ''"
          :icon-color="isMobile ? 'primary' : ''"
          icon-width="14"
          @click="removeCartItem(item.row_id)"
        />
      </div>
    </template>
    <!-- Empty Cart -->
    <template
      v-else
      class="c-cartEmpty"
    >
      <Typography
        class="-fontMedium -muted"
        v-text="$t('widgets.cart.emptyState.title')"
        variant="body2"
      />
      <Typography
        class="-fontMedium h-mv-10"
        v-text="$t('widgets.cart.emptyState.subTitle')"
        variant="body2"
      />
      <BaseButton
        color="primary"
        variant="solid"
        :btn-label="$t('widgets.cart.emptyState.buttonText')"
        :to="Trans.i18nRoutePath(`/dashboard/${selectedRestaurantId}`)"
      />
    </template>
  </div>
</template>

<script setup>
import {
  computed, ref, inject,
} from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import BaseButton from '@/components/common/BaseButton.vue';
import ItemQuantity from '@/components/common/Cart/ItemQuantity.vue';
import CardLoader from '@/components/common/Loader/CardLoader.vue';
import Trans from '@/mixins/translation';
import { generateKey } from '@/mixins/utils';

const store = useStore();
const router = useRouter();
const isMobile = inject('isMobile');
const cartItems = computed(() => store.state.cart.cart_items.products);
const hasItems = computed(() => !!cartItems?.value.length);
const isLoading = ref(null);
const isAuthenticated = computed(() => store.state.auth.isAuthenticated);
const selectedRestaurantId = computed(() => store.state.restaurant.selectedRestaurant.id);
const deliveryType = computed(() => store.state.order.orderData.delivery_type);
const props = defineProps({
  isCheckout: {
    type: Boolean,
  },
  isPayment: {
    type: Boolean,
  },
});

const matchInCart = productId => !!cartItems.value.find(product => product.id === productId);

const calculatePrice = ({ price, qty, variant_total_price }) => (
  `${((Number(price) * Number(qty)) + variant_total_price).toFixed(2)} €`);

const removeCartItem = row_id => {
  isLoading.value = row_id;
  store.dispatch(
    `cart/${isAuthenticated.value ? 'removeCartItems' : 'removeTempCartItems'}`,
    { row_id },
  ).then(() => {
    isLoading.value = null;
  });
};

const handleItemQty = async (newQty, { row_id, id, variants }) => {
  const newVariants = variants.reduce((acc, curr) => (
    [ ...acc, { id: curr.id, category: curr.category } ]), []);

  const params = {
    shop_id: selectedRestaurantId.value ?? 1,
    delivery_type: deliveryType.value,
    row_id,
    product: {
      id,
      qty: newQty,
      variants: newVariants,
    },
  };

  if (newQty > 0) {
    isLoading.value = row_id;
    await store.dispatch(`cart/${isAuthenticated.value ? 'updateCartItems' : 'updateTempCartItems'}`, params);
    isLoading.value = null;
  } else {
    await removeCartItem(row_id);
  }
};

const handleItemVariant = (newVariantQty, item, variant) => {
  const newItem = {
    ...item,
    variants: item.variants.map(i => (
      {
        ...i,
        qty: i.id === variant.id ? Number(newVariantQty) : i.qty,
      }
    )),
  };

  handleItemQty(item.qty, newItem);
};

const goToPdp = ({ slug, id }) => {
  store.dispatch('setIsPerfectMatch', true);
  const path = Trans.i18nRoutePath(`/item-details/${selectedRestaurantId.value}/${deliveryType.value}/${slug}/${id}`);

  router.push({ path });
};

</script>

<style lang="scss">
@use "src/assets/styles/abstracts/mixins" as mx;

.c-perfectMatchCta {
  margin-left: auto;

  svg {
    margin-right: 6px;
  }

  b {
    @include mx.use-color('bodyColorLt');
  }
}
</style>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-cartItems {
  padding: 20px 20px 10px;

  &.-isCheckout {
    padding: 20px 0;
  }

  &.-isEmpty {
    text-align: center;

    &::v-deep(.c-baseButton) {
      margin: auto;
    }
  }
}

.c-itemRow {
  $self: &;
  @include mx.d-flex('flex-start', 'flex-start');

  position: relative;

  margin-bottom: 10px;
  gap: 5px;

  @include mx.bp('phone') {
    gap: 10px;
  }

  &__titleWrapper {
    flex: 0 0 28%;

    margin-top: 0.4em;

    @include mx.bp('phone-md') {
      flex: 0 0 32%;
    }

    @include mx.bp('phone') {
      flex: 0 0 36%;
    }

    @include mx.bp('desktop') {
      flex: 0 0 49%;
    }
  }

  &__price {
    margin-left: auto;
    padding-top: 0.4em;

    &.-hasPerfectMatch {
      margin-left: 0;
    }
  }

  &__delete {
    margin-top: 0.5em;
  }

  &.-isCheckout {
    align-items: center;

    padding: 20px 0;

    border-bottom: 1px solid transparentize(v.$body-color-light, 0.95);

    #{ $self }__price {
      margin-bottom: 0;
    }
  }
}
</style>
