<template>
  <div :class="['c-cart', props.isPayment && '-hasShadow']">
    <div
      v-if="!props.isCheckout && !props.isPayment"
      class="c-cart__header"
    >
      <Typography
        v-if="disableOrder"
        v-text="$t('widgets.cart.minOrder', {price: convertTotal(minOrder, locale)})"
      />
      <Typography
        v-text="$t('widgets.cart.headerText', {price: '10,00€'})"
        variant="body2"
      />
    </div>
    <CartItems
      :is-checkout="props.isCheckout"
      :is-payment="props.isPayment"
    />
    <CartSubtotal
      v-if="hasItems"
      :is-checkout="props.isCheckout"
      :is-payment="props.isPayment"
    />
    <div
      v-if="hasItems && !props.isCheckout"
      class="c-cart__cta"
    >
      <Typography
        v-if="disableOrder"
        v-text="$t('widgets.cart.minOrder', {price: convertTotal(minOrder, locale)})"
        variant="body2"
        class="c-cart__minOrder"
      />

      <BaseButton
        color="primary"
        variant="solid"
        :btn-label="$t('widgets.cart.buttonText')"
        is-full-width
        :disabled="disableOrder"
        @click="handleOrder"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import CartItems from '@/components/CartItems.vue';
import CartSubtotal from '@/components/CartSubtotal.vue';
import BaseButton from '@/components/common/BaseButton.vue';
import Trans from '@/mixins/translation';
import { convertTotal } from '@/mixins/utils';
import { toast } from '@/toast-notification';

const store = useStore();
const router = useRouter();
const i18n = useI18n();
const { t, locale } = i18n;
const orderData = computed(() => store.state.order.orderData);
const cartId = computed(() => store.state.cart.cart_items.cart_id);
const hasItems = computed(() => !!store.state.cart.cart_items.products?.length);
const isTakeawayOrderValid = computed(() => store.getters['order/isTakeawayOrderValid']);
const isDeliveryOrderValid = computed(() => store.getters['order/isDeliveryOrderValid']);
const isCateringOrderValid = computed(() => store.getters['order/isCateringOrderValid']);
const isDeliveryOrder = computed(() => store.getters['order/isDeliveryOrder']);
const isTakeawayOrder = computed(() => store.getters['order/isTakeawayOrder']);
const isCateringOrder = computed(() => store.getters['order/isCateringOrder']);
const minOrder = computed(() => (isDeliveryOrder.value ? store.getters['reusable/getSetting']('order_min') : store.getters['reusable/getSetting']('order_min_takeaway')));
const cartTotal = computed(() => store.state.cart.cart_items.subTotalNoDiscount);
const disableOrder = computed(() => Number(cartTotal.value) < Number(minOrder.value));

const props = defineProps({
  isCheckout: {
    type: Boolean,
  },
  isPayment: {
    type: Boolean,
  },
});

const handleOrder = async () => {
  if (!props.isPayment) {
    return router.push(Trans.i18nRoutePath('/checkout'));
  }

  if (((isTakeawayOrder.value) && isTakeawayOrderValid.value)
      || ((isDeliveryOrder.value) && isDeliveryOrderValid.value)
      || (isCateringOrder.value && isCateringOrderValid.value)) {
    return store.dispatch('order/saveOrder', { ...orderData.value, cart_id: Number(cartId.value) });
  }

  toast.warning(t('common.feedback.KO.invalidCart'));

  return store.dispatch('order/hasOrderBeenSent', true);
};

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-cart {
  @include mx.use-bg-color("bodyColorDt");

  position: sticky;
  top: 150px;

  overflow: hidden;

  box-sizing: border-box;
  width: 100%;

  //border-radius: 0 0 v.$base-border-radius v.$base-border-radius;
  //box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

  &__header {
    @include mx.use-bg-color("grayLight");

    padding: 20px;

    border-bottom: 1px solid transparentize(v.$muted-color, 0.9);
    border-radius: v.$base-border-radius v.$base-border-radius 0 0;
  }

  &__cta {
    padding: 0 20px 20px;
  }

  &__minOrder {
    margin-bottom: 10px;

    font-weight: bold;
  }

  &.-hasShadow {
    border-radius: 6px;
    box-shadow: 0 10px 25px rgb(0 0 0 / 0.2);
  }
}
</style>
