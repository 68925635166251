<template>
  <div class="c-cardLoader" />
</template>

<script>
export default {
  name: 'CardLoader',
};
</script>

<style lang="scss" scoped>
.c-cardLoader {
  position: absolute;
  top: 0;

  overflow: hidden;

  width: 100%;
  height: 100%;

  animation-name: shimmer;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  opacity: 0.6;
  background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
  background-size: 1200px 100%;
  animation-fill-mode: forwards;
  @keyframes shimmer {
    0% {
      background-position: -1200px 0;
    }

    100% {
      background-position: 1200px 0;
    }
  }
}
</style>
