<template>
  <div :class="['c-cartSubtotal', isCheckout && '-isCheckout', props.isPayment && '-bgShadow']">
    <!--coupon-->
    <template v-if="isPayment">
      <div
        v-for="item in cartDiscounts"
        :key="item.id"
        class="c-cartSubtotal__item"
      >
        <BaseButton
          icon="delete"
          icon-color="primary"
          icon-width="16"
          color="muted"
          @click="removePromoCode(item.id)"
          :btn-label="item.text"
        />
        <Typography
          class="-fontMedium -muted"
          v-text="`- ${convertTotal(item.discount_amount, i18n.locale)}`"
          variant="body2"
        />
      </div>
    </template>

    <div
      v-if="isPayment && specialDiscount.discount_amount"
      class="c-cartSubtotal__item"
    >
      <Typography
        class="-muted"
        v-text="specialDiscount.text"
        variant="body2"
      />
      <Typography
        class="-fontMedium -muted"
        v-text="`${specialDiscount.discount_amount} €`"
        variant="body2"
      />
    </div>
    <div
      class="c-cartSubtotal__item"
      v-if="isPayment && hasItems"
    >
      <Typography
        class="-muted"
        v-text="$t('widgets.payment.section.cart.deliveryChargeText')"
        variant="body2"
      />
      <Typography
        class="-fontMedium -muted"
        v-text="convertTotal(deliveryCost, i18n.locale)"
        variant="body2"
      />
    </div>
    <div
      class="c-cartSubtotal__item"
      v-if="isPayment && hasItems"
    >
      <Typography
        class="-muted"
        v-text="$t('widgets.payment.section.cart.serviceChargeText')"
        variant="body2"
      />
      <Typography
        class="-fontMedium -muted"
        v-text="convertTotal(serviceCharge, i18n.locale)"
        variant="body2"
      />
    </div>
    <div class="c-cartSubtotal__item">
      <Typography
        v-if="(!isCheckout && !isMobile) || !isMobile || isPayment"
        class="-primary h-mr-10"
        v-text="$t(isPayment ? 'widgets.cart.totalText' : 'widgets.cart.subTotalText')"
        variant="body1"
      />
      <Typography
        v-if="(!isCheckout && !isMobile) || !isMobile || isPayment"
        class="-fontBook"
        v-text="convertedAmount"
        variant="subtitle1"
      />
    </div>
    <div
      class="c-cartSubtotal__item"
      v-if="isPayment && hasItems && hasUsedCredit2"
    >
      <Typography
        class="-muted"
        v-text="$t('widgets.payment.section.cart.paidWithPrepaidCredit')"
        variant="body2"
      />
      <Typography
        class="-fontMedium -muted"
        v-text="convertTotal(userCredit2, i18n.locale)"
        variant="body2"
      />
    </div>
    <div
      class="c-cartSubtotal__item"
      v-if="isPayment && hasItems && hasUsedCredit"
    >
      <Typography
        class="-muted"
        v-text="$t('widgets.payment.section.cart.paidWithCredit')"
        variant="body2"
      />
      <Typography
        class="-fontMedium -muted"
        v-text="convertTotal(userCredit, i18n.locale)"
        variant="body2"
      />
    </div>
    <div
      class="c-cartSubtotal__item"
      v-if="isPayment && hasItems && (hasUsedCredit2 || hasUsedCredit)"
    >
      <Typography
        class="-primary"
        v-text="$t('widgets.payment.section.cart.remainder')"
        variant="body1"
      />
      <Typography
        class="-fontBold"
        v-text="remainder"
        variant="body2"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import BaseButton from '@/components/common/BaseButton.vue';
import { convertTotal } from '@/mixins/utils';

const isMobile = inject('isMobile');
const store = useStore();
const i18n = useI18n();
const cartItemsProducts = computed(() => store.state.cart.cart_items.products);
const cartId = computed(() => store.state.cart.cart_items.cart_id);
const hasItems = computed(() => !!cartItemsProducts?.value.length);
const specialDiscount = computed(() => store.state.cart.cart_items.specialDiscount);
const deliveryCost = computed(() => store.state.cart.cart_items.delivery_cost || 0);
const serviceCharge = computed(() => (store.state.cart.cart_items.service_cost || 0));
const subtotal = computed(() => (store.state.cart.cart_items.subTotal || 0));
const total = computed(() => (store.state.cart.cart_items.total || 0));
const formattedSubtotalDiscounted = computed(() => store.getters['cart/getFormattedSubTotalDiscounted']);
const isAuthenticated = computed(() => store.state.auth.isAuthenticated);
const cartDiscounts = computed(() => store.state.cart.cart_items.discounts);
const hasUsedCredit = computed(() => !!store.state.cart.cart_items.credit);
const hasUsedCredit2 = computed(() => !!store.state.cart.cart_items.credit2);
const userCredit = computed(() => Number(store.state.auth.user.credit));
const userCredit2 = computed(() => Number(store.state.auth.user.credit2));
const props = defineProps({
  isCheckout: {
    type: Boolean,
  },
  isPayment: {
    type: Boolean,
  },
});

const convertedAmount = computed(() => {
  if (props.isPayment) return convertTotal(total.value, i18n.locale.value);

  return formattedSubtotalDiscounted.value ?? convertTotal(subtotal.value, i18n.locale.value);
});

const remainder = computed(() => {
  // NOTE: this runs only on payment page for users with credit
  if (!props.isPayment) return false;

  let finalPrice = Number((total.value ?? '0.00 €').replace(' €', ''));

  // If the user has applied credit2
  if (hasUsedCredit2.value) {
    finalPrice = userCredit2.value > finalPrice ? 0 : finalPrice - userCredit2.value;
  }

  // If the user has applied credit
  if (hasUsedCredit.value) {
    finalPrice = userCredit.value > finalPrice ? 0 : finalPrice - userCredit.value;
  }

  return convertTotal(finalPrice, i18n.locale.value);
});

const removePromoCode = async id => {
  const params = {
    discountCode: id,
    ...!isAuthenticated.value && { cart_id: cartId.value },
  };

  await store.dispatch('cart/removeCoupon', params);
};

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-cartSubtotal {
  $self: &;

  padding: 20px 20px 0;

  box-shadow: 0 -2px 6px rgb(0 0 0 / 0.05);

  &__item {
    @include mx.d-flex("space-between", "center");
  }

  &.-isCheckout {
    box-shadow: unset;

    #{ $self }__item {
      justify-content: flex-end;
      gap: 20px;
    }
  }
}
</style>
